import { useContext, useEffect, useState, Fragment } from 'react';
import { useBoolean } from '@fluentui/react-hooks';
import { Stack, StackItem, IconButton, DatePicker, DayOfWeek, IStackTokens, DefaultButton, addDays } from '@fluentui/react';

import Common from '../Common/Common';
import { goalsControlContext } from './GoalsControl';
import GlobalState from "../GlobalState";
import { VisitModel } from "../Models/VisitModel";
import { GoalModel } from '../Models/GoalModel';
import { cancelIcon, contentStyles, iconButtonStyles } from '../Common/ModalHelper';
import { FontIcon } from '@fluentui/react/lib/Icon';
import { mergeStyles } from '@fluentui/react/lib/Styling';

import { GoalReminder } from './GoalReminder';
import TokenModel from '../Models/TokenModel';
import GoalsHelper from '../ComponentHelpers/GoalsHelper';
import { Events, RequestType } from '../Common/Enum';
import Emitter from "../Common/EventEmitter";
import { IMessagebarObject } from "./MasterControl";
import { GoalsCreationMode } from '../Common/Enum';

const iconClass = mergeStyles({ fontSize: 20, marginRight:'10px', color:'#5BB4D8' });
const itemAlignmentsStackTokens: IStackTokens = { childrenGap: 5, padding: 5 };

export const AddGoalControl: React.FunctionComponent<AddGoalControlProps> = (props) => {

    const { goalsCreationMode, visitModel, goalModel, goalsHelper, hideGoalsModal } = props;

    const goalsControlDispatcher = useContext(goalsControlContext);
    const globalState = useContext(GlobalState);

    const [isGoalCreating, { setTrue: showGoalsLoader, setFalse: hideGoalsLoader }] = useBoolean(false);
    const [goalsDescription, setGoalsDescription] = useState("");
    const [goalCharacterCount, setGoalCharacterCount] = useState(0);
    const [goalsDueDate, setGoalsDueDate] = useState<Date | undefined>();

    const [isWorkplaceReminderChecked, setIsWorkplaceReminderChecked] = useState<boolean>(false)
    const [workplaceReminderTypes, setworkplaceReminderTypes] = useState<number[]>([1])
    const [workplaceReminderDate, setWorkplaceReminderDate] = useState<Date>()

    const [isLearnerReminderChecked, setIsLearnerReminderChecked] = useState<boolean>(false)
    const [learnerReminderTypes, setLearnerReminderTypes] = useState<number[]>([])
    const [learnerReminderDate, setLearnerReminderDate] = useState<Date>()

    const [isTaReminderChecked, setIsTaReminderChecked] = useState<boolean>(false)
    const [taReminderTypes, setTaReminderTypes] = useState<number[]>([])
    const [taReminderDate, setTaReminderDate] = useState<Date>()

    const [isLoading, setIsLoading] = useState(true);

    const visitDate = new Date(visitModel.tims_dateofvisit as string);
    const minGoalDate = addDays(visitDate, 1);

    // Set default UI values for editing existing goals
    // When editing an existing goal, this useEffect hook initializes the UI elements
    // with default values to ensure the user sees the current state of the goal they are modifying.
    useEffect(() => {
            if (goalModel) {
                if (goalModel.fus_duedate) {
                    setGoalsDueDate(new Date(goalModel.fus_duedate));
                }
                if (goalModel.fus_description) {
                    setGoalsDescription(goalModel.fus_description);
                    setGoalCharacterCount(goalModel.fus_description.length);
                }
                if (goalModel.tims_workplacereminderdate) {
                    setIsWorkplaceReminderChecked(true);
                    setWorkplaceReminderDate(new Date(goalModel.tims_workplacereminderdate));
                }
                if (goalModel.tims_learnerreminderdate) {
                    setIsLearnerReminderChecked(true);
                    setLearnerReminderDate(new Date(goalModel.tims_learnerreminderdate));
                    setLearnerReminderTypes(goalModel.tims_learnerremindertype?.split(",").map(Number) || [])
                }
                if (goalModel.tims_trainingadviserreminderdate) {
                    setIsTaReminderChecked(true);
                    setTaReminderDate(new Date(goalModel.tims_trainingadviserreminderdate));
                    setTaReminderTypes(goalModel.tims_trainingadviserremindertype?.split(",").map(Number) || [])
                }
            }
            setIsLoading(false)
    }, [])

    // Disable save button if information has not been populated
    const canSaveGoal = () => {
      const isWorkplaceValid = !isWorkplaceReminderChecked || (workplaceReminderDate && workplaceReminderTypes.length > 0);
      const isLearnerValid = !isLearnerReminderChecked || (learnerReminderDate && learnerReminderTypes.length > 0);
      const isTaValid = !isTaReminderChecked || (taReminderDate && taReminderTypes.length > 0);
      const isDescriptionValid = goalsDescription.trim() !== "";
      
      return isWorkplaceValid && isLearnerValid && isTaValid && isDescriptionValid && !isGoalCreating && goalsDueDate;
    };

    // Create or update goal, dependant on goalsCreationMode
    const saveGoal = async () => {
      showGoalsLoader();
      let newGoalModel = new GoalModel(globalState.tokenModel as TokenModel);
      let visitId = visitModel.tims_macvisitid as string;
      var createdGoal = {} as GoalModel;
      if (goalsCreationMode == GoalsCreationMode.Create){
          createdGoal = await goalsHelper.createGoal(goalsDescription, goalsDueDate as Date, visitId, taReminderTypes, learnerReminderTypes, taReminderDate, workplaceReminderDate, learnerReminderDate) as GoalModel;
      }
      else {
        let updateResponse = await goalsHelper.updateGoal(goalsDescription, goalsDueDate as Date, goalModel?.fus_goalid as string, taReminderTypes, learnerReminderTypes, taReminderDate, workplaceReminderDate, learnerReminderDate);
        if (!updateResponse.ok) createdGoal = await updateResponse.json() as GoalModel;
      }
      let requestType = goalsCreationMode == GoalsCreationMode.Create ? RequestType.CREATE_GOAL : RequestType.UPDATE_GOAL;
      if (createdGoal.error != undefined){
        Emitter.emit(Events.SetMessageBar, { errors: [createdGoal.error.message], isSuccess: false, requestType: requestType, showMessageBar: true } as IMessagebarObject);
      }
      else {
        Emitter.emit(Events.SetMessageBar, { errors: [], isSuccess: true, requestType: requestType, showMessageBar: true } as IMessagebarObject);
        if(typeof goalsControlDispatcher == "function"){
          if (goalsCreationMode == GoalsCreationMode.Create) {
            createdGoal = await newGoalModel.retrieveGoal(createdGoal.fus_goalid as string);
            goalsControlDispatcher({ type: "POST_GOALS_CREATE", obj: { goal: createdGoal, visitId: visitId } });
          }
          else {
            let updatedGoal = await newGoalModel.retrieveGoal(goalModel?.fus_goalid as string);
            goalsControlDispatcher({ type: "POST_GOALS_UPDATE", obj: { goalObj: updatedGoal, visitId: visitId } });
          }
        }
      }
      hideGoalsLoader();
      hideGoalsModal();
    }
    
    return (
        <>
          <div className={contentStyles.header}>
            <span id="Add_Goals">{goalsCreationMode == GoalsCreationMode.Create ? "Create Goal" : "Update Goal"}</span>
            {isGoalCreating ? 
              <div style={{ marginLeft: "10px" }} className="lds-dual-ring"></div> : ""}
                <IconButton styles={iconButtonStyles} iconProps={cancelIcon} ariaLabel="Close popup modal" onClick={hideGoalsModal}/>
              </div>
            {!isLoading &&
              <Stack className={contentStyles.body}>
                <StackItem>
                  <textarea 
                    disabled = {goalsCreationMode == GoalsCreationMode.UpdateReminders} 
                    style={{ width: "400px", height: "250px" }} 
                    maxLength={4000} 
                    rows={3} 
                    placeholder="Comment" 
                    value={goalsDescription} 
                    onChange={(e) => {
                      setGoalCharacterCount(e.target.value.length);
                      setGoalsDescription(e.target.value)
                    }}/>
                  <br/>
                  <b>Text limit:</b> 
                  {goalCharacterCount} / 4000
                </StackItem>
                <StackItem>
                  <b>Due Date:</b>
                  <DatePicker 
                    disabled={goalsCreationMode == GoalsCreationMode.UpdateReminders} 
                    value={goalsDueDate} 
                    firstDayOfWeek={DayOfWeek.Sunday} 
                    minDate={minGoalDate} 
                    onSelectDate={(date) => {
                      if (date != null && date != undefined) setGoalsDueDate(date);
                    }} ariaLabel="Select a date" />
                </StackItem>
                {goalsDueDate !== undefined && (
                <StackItem>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    {Common.getDifferenceInDays(new Date(), goalsDueDate) >= 0 ? (  
                    <Fragment>
                      <FontIcon aria-label="Info" iconName="Info" className={iconClass} style={{ marginRight: '5px', marginTop: '1px' }} />
                        This due date is {Common.getDifferenceInDays(new Date(), goalsDueDate)} day{Common.getDifferenceInDays(new Date(), goalsDueDate) !== 1 ? 's' : ''} from today
                    </Fragment>
                    )  : (
                    <Fragment>
                      <FontIcon aria-label="Info" iconName="Info" className={iconClass} style={{ marginRight: '5px', marginTop: '1px' }} />
                      <span style={{ display: 'inline-flex', alignItems: 'center' }}>
                        This due date is in the past
                      </span>
                    </Fragment>
                    )}
                  </div>
                </StackItem>
              )}
              <p><b>Goal Reminders:</b></p>
              <StackItem tokens={itemAlignmentsStackTokens}>
                <GoalReminder
                  reminderName="Workplace contact"
                  reminderDate={workplaceReminderDate}
                  reminderTypeOptions = {[ {key:1, text:"Email"} ]}
                  dueDate={goalsDueDate}
                  isChecked={isWorkplaceReminderChecked}
                  reminderTypes={workplaceReminderTypes}
                  setIsChecked={setIsWorkplaceReminderChecked}
                  setReminderTypes={setworkplaceReminderTypes}
                  setReminderDate={setWorkplaceReminderDate} />
              </StackItem>
              <StackItem tokens={itemAlignmentsStackTokens}>
                <GoalReminder
                  reminderName="Learner"
                  reminderDate={learnerReminderDate}
                  reminderTypeOptions = {[ {key:1, text:"Email"}, {key:2, text:"Text Message"} ]}
                  dueDate={goalsDueDate}
                  isChecked={isLearnerReminderChecked}
                  reminderTypes={learnerReminderTypes}
                  setIsChecked={setIsLearnerReminderChecked}
                  setReminderTypes={setLearnerReminderTypes}
                  setReminderDate={setLearnerReminderDate}/>
              </StackItem>
              <StackItem tokens={itemAlignmentsStackTokens}>
                <GoalReminder
                  reminderName="Training Adviser"
                  reminderDate={taReminderDate}
                  reminderTypeOptions = {[ {key:1, text:"Email"}, {key:2, text:"Text Message"} ]}
                  dueDate={goalsDueDate}
                  isChecked={isTaReminderChecked}
                  reminderTypes={taReminderTypes}
                  setIsChecked={setIsTaReminderChecked}
                  setReminderTypes={setTaReminderTypes}
                  setReminderDate={setTaReminderDate}/>
              </StackItem>
              <StackItem>
                <DefaultButton 
                disabled={!canSaveGoal()} 
                style={{ float: "right", marginTop: "7%" }} 
                className="button-style" 
                onClick={async (e) => {saveGoal()}}>Save</DefaultButton>
          </StackItem>
        </Stack> }
      </>
    )
}

interface AddGoalControlProps {
  goalsCreationMode:GoalsCreationMode
  visitModel:VisitModel,
  goalModel?:GoalModel
  goalsHelper:GoalsHelper,
  hideGoalsModal:() => void;
}