import React, { useContext, useState } from 'react';
import { Modal, DefaultButton, IDragOptions, ContextualMenu } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import { Col, Row } from 'react-bootstrap';

import GlobalState from "../GlobalState";
import Common from '../Common/Common';
import ComponentHelperBase from '../ComponentHelpers/ComponentHelperBase';
import GoalsAndVisits from '../ComponentHelpers/GoalsAndVisits';
import GoalsHelper from '../ComponentHelpers/GoalsHelper';

import { GoalRowControl } from './GoalRowControl';
import { AddGoalControl } from './AddGoalControl';
import { goalsControlContext } from './GoalsControl';
import { contentStyles } from '../Common/ModalHelper';

import { VisitModel, VisitsStatusCode } from "../Models/VisitModel";
import { GoalModel } from '../Models/GoalModel';
import { GoalsCreationMode } from '../Common/Enum';

const dragOptions: IDragOptions = {
  moveMenuItemText: 'Move',
  closeMenuItemText: 'Close',
  menu: ContextualMenu,
  dragHandleSelector: '.ms-Modal-scrollableContent > div:first-child',
};

export const VisitGridControl: React.FunctionComponent<VisitsGridControlProps> = (props) => {
    const { goalsHelper } = props
    const visits = goalsHelper.rows
    const goalsControlDispatcher = useContext(goalsControlContext);
    const globalState = useContext(GlobalState);
    const [goalsCreationMode, setGoalsCreationMode] = useState<GoalsCreationMode>(GoalsCreationMode.Create);
    const [currentRowIndex, setCurrentRowIndex] = useState(0);
    const [isAddGoalsModalOpen, { setTrue: showGoalsModal, setFalse: hideGoalsModal }] = useBoolean(false);

    const renderDialogAddGoals = () => {
        const index = currentRowIndex;
        if (index + 1 <= visits.length) {
          const visitModel = (visits[index] as GoalsAndVisits)._visit;
          
          return (
            <Modal isDarkOverlay={false}
              titleAriaId="Add Goals"
              isOpen={isAddGoalsModalOpen}
              onDismiss={hideGoalsModal}
              isModeless={true}
              containerClassName={contentStyles.container}
              dragOptions={dragOptions}
              >
                <AddGoalControl
                    goalsCreationMode={goalsCreationMode}
                    visitModel={visitModel}
                    goalsHelper={goalsHelper as GoalsHelper}
                    hideGoalsModal={hideGoalsModal}
                >
                </AddGoalControl>
            </Modal >
          );
        } else
          return <></>;
      }
    
    return <>
    {visits.map((visit, index) => (
      <div key={index} style={{ marginBottom: "15px" }}>
        <Row className="visit-row">
          <Col className="goals-col">
            <div style={{ width: "50%", float: "left" }}>
              <div style={{ width: "40%", float: "left" }}>
                <p className="mb-0"><b>Visit date:</b></p>
              </div>
              <div>
                <p className="mb-0">{Common.getFormattedValue((visit as GoalsAndVisits)._visit, "tims_dateofvisit")}</p>
              </div>
            </div>
            <div style={{ width: "50%", float: "left" }}>
              <div style={{ width: "40%", float: "left" }}>
                <p className="mb-0"><b>Type:</b></p>
              </div>
              <div style={{ float: "left" }}>
                <p className="mb-0">{Common.getFormattedValue((visit as GoalsAndVisits)._visit, "tims_visittype")} 
                 {(visit as GoalsAndVisits)._visit.tims_visittypeothertext && ` - ${(visit as GoalsAndVisits)._visit.tims_visittypeothertext}`}
                </p>
              </div>
              {!globalState.IsPortalMode && (visit as GoalsAndVisits)._visit.statuscode == VisitsStatusCode.Pending ?
                <div style={{ float: "left", marginLeft: "5%" }}>
                  <a className="grid-button" href="#" onClick={(e) => {
                    if(typeof goalsControlDispatcher == "function"){
                        goalsControlDispatcher({ type: "SHOW_VISIT_MODAL", obj: { visitId: visit._visit?.tims_macvisitid } });
                    }
                  }}>Edit</a>
                </div>
                : <></>}
            </div>
          </Col>
          <Col className="goals-col"></Col>
        </Row>
        {(visit as GoalsAndVisits)._visit.goals.map((goalModel: GoalModel, index) => (
          <GoalRowControl
            key={index}
            data={visit as GoalsAndVisits}
            visit={visit._visit as VisitModel}
            goalsHelper={goalsHelper as GoalsHelper}
            goal={goalModel}
          />
        ))}
        {(visit as GoalsAndVisits).isVisitPending ? <Row>
          <Col className="goals-col">
            <div style={{ float: "left" }}>
              {!globalState.IsPortalMode ?
                <DefaultButton style={{ marginTop: "13px" }} className="grid-button button-style" onClick={async (e) => {
                  setGoalsCreationMode(GoalsCreationMode.Create);
                  setCurrentRowIndex(index);
                  showGoalsModal();
                }}>Add Goal</DefaultButton> : <></>}
            </div></Col>
          <Col className="goals-col">
          </Col>
        </Row> : <></>}
      </div>
    ))
    }
    <>
    {renderDialogAddGoals()}
    </>
  </>
}

interface VisitsGridControlProps {
    goalsHelper: ComponentHelperBase,
}
