import React, { useState } from "react";
import GlobalState from "../GlobalState";
import { useBoolean, useId } from '@fluentui/react-hooks';
import Result from "../ComponentHelpers/Result";
import { GridControl } from "./GridControl";
import { Events, RequestType, SortReorderCode, Templates, Views } from "../Common/Enum";
import { cancelIcon, contentStyles, iconButtonStyles } from '../Common/ModalHelper';
import { ChoiceGroup, ContextualMenu, DefaultButton, Dialog, DialogFooter, DialogType, hiddenContentStyle, IChoiceGroupOption, mergeStyles, PrimaryButton, Stack, Modal, IconButton, StackItem, Checkbox, IStackTokens, TextField, IStackItemStyles, composeComponentAs, IDropdownOption, IDragOptions} from "@fluentui/react";
import Loader from "react-loader-spinner";
import arrayMove from "array-move";
import cloneDeep from 'lodash/cloneDeep';
import ReorderHelper from '../ComponentHelpers/ReorderHelper';
import Emitter from "../Common/EventEmitter";
import { Serializable } from "../Models/Serializable";
import GlobalStateModel from "../Models/GlobalStateModel";
import acheivedFirst from "../assets/images/Re-order - Achieved first.png";
import acheivedFirstGrey from "../assets/images/TPlan - Achieved first grey.png";
import acheivedLast from "../assets/images/Re-order - Achieved last.png";
import acheivedLastGrey from "../assets/images/TPlan - Achieved last grey.png";
import byUnitID from "../assets/images/TPlan - By unit.png";
import byUnitIDGrey from "../assets/images/TPlan - By unit grey.png";
import { IMessagebarObject, MasterControlContext, TrainingPlanViewsContext } from "./MasterControl";
import TrainingPlanUnitStandardModel from "../Models/TrainingPlanUnitStandardModel";
import TokenModel from "../Models/TokenModel";
import ResultForm from "../ComponentHelpers/ResultForm";
import Common from "../Common/Common";
import { SummaryObject } from "./ElectiveGroupControl";
import { TrainingPlanViewColumns, TrainingPlanViewModel } from "../Models/TrainingPlanViewModel";

interface ReorderContState {
    reorderHelp: ReorderHelper,
    showLoader: boolean,
    trainingPlanView:TrainingPlanViewModel
}

export const reorderControlContext = React.createContext({});
const itemAlignmentsStackTokens: IStackTokens = { childrenGap: 5, padding: 5 };

const dialogStyles = { main: { maxWidth: 450 } };

const screenReaderOnly = mergeStyles(hiddenContentStyle);

const dialogContentProps = {
    type: DialogType.largeHeader,
    title: `Let's get it sorted`,
    closeButtonAriaLabel: 'Close',
    subText: "Units on this Training Plan have not been ordered yet. Please pick the initial sort order:",
};

const deleteViewDialogContentProps = {
    type: DialogType.normal,
    title: 'Confirm Deletion',
    closeButtonAriaLabel: 'Close',
    subText: `Are you sure you want to delete this saved view? This action cannot be undone.`,
};

const columnCheckboxOptions = [
    { key: 'tims_sequencenumber', label: 'Sequence Number', disabled:true },
    { key: 'tims_unitstandardname', label: 'Unit Standard Name', disabled:true },
    { key: 'tims_credits', label: 'Credits', disabled:true },
    { key: 'tims_level', label: 'Level', disabled:true },
    { key: 'tims_assessmenttype', label: 'Assessment Type', disabled:true },
    { key: 'tims_result', label: 'Result', disabled:true },
    { key: 'tims_course', label: 'Course' },
    { key: 'tims_coursestartdate', label: 'Course Start Date' },
    { key: 'tims_courseidandstartdate', label: 'Course code and start date' },
    { key: 'tims_assessmentstatus', label: 'Assessment Status' },
    { key: 'tims_assessmentstatusfinal', label: 'Resubmission Status' },
    { key: 'tims_assessor', label: 'Assessor' },
    { key: 'tims_verifier', label: 'Verifier' },
    { key: 'tims_comments', label: 'Comments' },
  ];

  let defaultColumns = {
    tims_sequencenumber: true, tims_unitstandardname: true, tims_credits: true,
    tims_level: true, tims_assessmentstatus: false, tims_assessmenttype: true,
    tims_assessmentstatusfinal: false, tims_courseidandstartdate: false, 
    tims_coursestartdate: false, tims_result: true, tims_course: false,
    tims_assessor: true, tims_verifier: true, tims_comments: true
  } as TrainingPlanViewColumns;


export const ReorderControl: React.FunctionComponent<{}> = () => {

    const globalState = React.useContext(GlobalState);
    const ref = React.useRef(true);

    const masterControlDispatcher = React.useContext(MasterControlContext);
    const trainingPlanViewContext = React.useContext(TrainingPlanViewsContext);

    const [hideDialog, { toggle: toggleHideDialog, setFalse: setHideDialogFalse, setTrue: sethideDialogTrue }] = useBoolean(true);

    const [isCreateViewModalOpen, { setFalse: hideCreateViewModal, setTrue: showCreateViewModal }] = useBoolean(false);
    const [isSaveViewModalOpen, { setFalse: hideSaveViewModal, setTrue: showSaveViewModal }] = useBoolean(false);
    const [isDeleteViewDialogOpen, { setFalse: hideDeleteViewDialog, setTrue: showDeleteViewDialog }] = useBoolean(false);
    const [isDeletingVisit, { toggle: toggleDeletingVisit }] = useBoolean(false);

    const [isDraggable, { toggle: toggleIsDraggable }] = useBoolean(false);
    const [sortCode, setSortCode] = useState<null | SortReorderCode>(SortReorderCode.ResultFirst);
    const [viewName, setViewName] = useState("")
    const [selectedColumns, setSelectedColumns] = useState<TrainingPlanViewColumns>(defaultColumns)
    const labelId: string = useId('dialogLabel');
    const subTextId: string = useId('subTextLabel');


    const options: IChoiceGroupOption[] = [
        {
            key: 'AcheivedFirst',
            text: 'Achieved First',
        },
        {
            key: 'AcheivedLast',
            text: 'Achieved Last'
        },
        {
            key: 'ByUnitID',
            text: 'By Unit ID'
        },
    ];

    const modalProps = React.useMemo(
        () => ({
            titleAriaId: labelId,
            subtitleAriaId: subTextId,
            isBlocking: false,
            styles: dialogStyles,
            dragOptions: isDraggable ? dragOptions : undefined,
        }),
        [isDraggable, labelId, subTextId],
    );

    const dragOptions: IDragOptions = {
        moveMenuItemText: 'Move',
        closeMenuItemText: 'Close',
        menu: ContextualMenu,
        dragHandleSelector: '.ms-Modal-scrollableContent > div:first-child',
      };

    const retrieveColumns = async () => {
        let columns = defaultColumns
        if(globalState.selectedView == Views.Custom){
            let trainingPlanViewModel = new TrainingPlanViewModel(globalState.tokenModel as TokenModel)
            columns = await trainingPlanViewModel.retrieveTrainingPlanView(globalState.selectedCustomView as string)
        }
        return columns
    }


    React.useEffect(() => {
        const initializeDataLoad = async () => {
            let reorderHelper = new ReorderHelper(globalState);   
            
            await reorderHelper.build();

            reorderHelper.sortBySequenceNumber();

            let tpusWithoutSeqNumber = reorderHelper.rows.filter(item => item._tpuModel.fus_sequencenumber == undefined);
            if (tpusWithoutSeqNumber.length > 0 && !globalState.IsPortalMode)
                setHideDialogFalse();

            if (!ref.current)
                return;

            if (globalState.IsPortalMode) {
                if (masterControlDispatcher != undefined) {
                    if (typeof masterControlDispatcher == "function")
                        masterControlDispatcher({
                            type: "INIT_SUMMARY", obj: { selected: reorderHelper.selectedCredits, achieved: reorderHelper.achievedCredits, remaining: reorderHelper._remainingCredits, id: Common.newGuid() } as SummaryObject
                        });
                }
            }
            let columns = await retrieveColumns()

            dispatch({ type: "INIT", obj: { reorderHelp: reorderHelper, showLoader: false, trainingPlanView:columns } as ReorderContState });

            Emitter.on(Events.SaveOrder, async () => {
                dispatch({ type: "SAVE_ORDER" })
            });

            Emitter.on(Events.OpenCreateViewModal, async () => {
                setViewName("")
                showCreateViewModal()
            })

            Emitter.on(Events.OpenSaveViewModal, async () => {
                showSaveViewModal()
            })

            Emitter.on(Events.DeleteView, async () => {
                showDeleteViewDialog()
            })

            ref.current = false;
        }
        initializeDataLoad();

        return () => {
            Emitter.off(Events.SaveOrder, () => { });
            Emitter.off(Events.OpenCreateViewModal, () => { });
            Emitter.off(Events.OpenSaveViewModal, () => { });
            Emitter.off(Events.DeleteView, () => { });

        };
    }, []);


    React.useEffect(() => {
        const updateSelectedColumns = async () => {
            let columns = await retrieveColumns()
            setSelectedColumns(columns)
            dispatch({type:"SAVE_COLUMNS", obj:{trainingPlanView:columns}})
        }

        let globalContext = {
            ...globalState,
            isColumnsDirty:false
        } as GlobalStateModel
        globalState.setGlobalState(globalContext)
        updateSelectedColumns()
        
    }, [globalState.selectedCustomView])


    const sortTPUS = () => {
        if (sortCode == SortReorderCode.ResultFirst || sortCode == SortReorderCode.ResultLast) {
            reorderState.reorderHelp.sortByResult(sortCode);
            reorderState.reorderHelp.setSequenceNumberForTPUS(false);
            let globalContext = {
                ...globalState,
                IsReorderDirty: true
            } as GlobalStateModel;
            globalState.setGlobalState(globalContext);
            dispatch({ type: "INIT", obj: { reorderHelp: reorderState.reorderHelp, showLoader: false, trainingPlanView: reorderState.trainingPlanView } as ReorderContState });
        }
        else if (sortCode == SortReorderCode.ByUnits) {
            if (reorderState.reorderHelp.rows != undefined) {
                reorderState.reorderHelp.sortByFrameworkElementCode();
                let globalContext = {
                    ...globalState,
                    IsReorderDirty: true
                } as GlobalStateModel;
                globalState.setGlobalState(globalContext);
                reorderState.reorderHelp.setSequenceNumberForTPUS(false);
                dispatch({ type: "INIT", obj: { reorderHelp: reorderState.reorderHelp, showLoader: false, trainingPlanView:reorderState.trainingPlanView } as ReorderContState });
            }
        }
    }

    const toggleFullScreenLoader = (showLoader: boolean) => {
        let globalContext = {
            ...globalState,
            IsFullScreenLoaderVisible: showLoader
        } as GlobalStateModel;
        globalState.setGlobalState(globalContext);
    }

    const saveOrder = async (reorderStateb: ReorderContState) => {
        toggleFullScreenLoader(true);
        let updateResponses = await reorderStateb.reorderHelp.saveSeqNum();
        let errorArr = [];
        for (let index = 0; index < updateResponses.length; index++) {
            const element = updateResponses[index];
            if (!element.ok) {
                let errorObj = await element.json() as Serializable;
                errorArr.push(errorObj.error?.message);
            }
        }
        Emitter.emit(Events.SetMessageBar, {
            errors: errorArr, isSuccess: updateResponses.every(item => item.ok),
            requestType: RequestType.SAVE_ORDER, showMessageBar: true
        } as IMessagebarObject);

        let tpuRep = new TrainingPlanUnitStandardModel(globalState.tokenModel as TokenModel);
        await tpuRep.refreshSeqNumber(globalState.trainingPlanObj?.tims_trainingplanid as string);

        dispatch({ type: "POST_SAVE", obj: { updateResponses: updateResponses } });
    }

    const checkForDirty = () => {
        let dirty = false
        for(let key in selectedColumns){
            if (selectedColumns[key as keyof TrainingPlanViewColumns]  !== defaultColumns[key as keyof TrainingPlanViewColumns]) {
                dirty = true
              }
        }
        let globalContext = {
            ...globalState,
            isColumnsDirty:dirty
        } as GlobalStateModel
        globalState.setGlobalState(globalContext)
    }
    const renderCreateViewModal = () => {
        return(
        <Modal isDarkOverlay={false}
            titleAriaId="Add Visit"
            isOpen={isCreateViewModalOpen}
            onDismiss={hideCreateViewModal}
            isBlocking={false}
            containerClassName={contentStyles.container}
            >
                <div className={contentStyles.header}>
                <span id="Create_View">Edit Columns</span>
                    <IconButton
                        styles={iconButtonStyles}
                        iconProps={cancelIcon}
                        ariaLabel="Close popup modal"
                        onClick={hideCreateViewModal}
                    />
                </div>
                <Stack className={contentStyles.body} tokens={itemAlignmentsStackTokens}>
                    {columnCheckboxOptions.map(c => 
                         <StackItem key={c.key}>
                            <Checkbox disabled = {c.disabled} label={c.label}
                            checked={selectedColumns[c.key as keyof TrainingPlanViewColumns]} 
                            onChange={(e, checked) => { 
                                if(checked != undefined) setSelectedColumns({...selectedColumns, [c.key]:checked})
                            }}   
                            />
                    </StackItem>
                    )}
                    <StackItem>
                        <DefaultButton style={{ float: "right", marginTop: "7%" }} className="button-style" onClick={async (e) => {
                            dispatch({type:"SAVE_COLUMNS", obj:{trainingPlanView:selectedColumns}})
                            checkForDirty()
                            hideCreateViewModal()
                        } }>
                            OK
                        </DefaultButton>
                    </StackItem>
                </Stack>
        </Modal>
        )
    }

    const renderDeleteViewModal = () => {
        return (
            <Dialog
            hidden={!isDeleteViewDialogOpen}
            onDismiss={hideDeleteViewDialog}
            dialogContentProps={deleteViewDialogContentProps}
            modalProps={modalProps}
        >
            <DialogFooter>
                {isDeletingVisit ? <div style={{ marginLeft: "10px", paddingTop: "13px" }} className="lds-dual-ring"></div> : ""}
                <PrimaryButton 
                disabled={isDeletingVisit} 
                onClick={async (e) => {
                    toggleDeletingVisit()
                    await deleteTrainingPlanView();
                    hideDeleteViewDialog();
                    toggleDeletingVisit()
                }} text="Yes" />
                <DefaultButton 
                disabled={isDeletingVisit} 
                onClick={hideDeleteViewDialog} text="No" />
            </DialogFooter>
        </Dialog>);
    }

    const renderSaveViewModal = () => {
        return (
            <Modal isDarkOverlay={false}
            titleAriaId="Save View"
            isOpen={isSaveViewModalOpen}
            onDismiss={hideSaveViewModal}
            isBlocking={false}
            containerClassName={contentStyles.container}>
                <div className={contentStyles.header}>
                    <span id="Save_View">Save View</span>
                </div>
                    <Stack className={contentStyles.body} tokens={itemAlignmentsStackTokens}>
                     <StackItem styles={{root: { marginBottom:15  }}}>
                        <TextField
                            placeholder="View Name"
                            value={viewName}
                            onChange={(e, text) => {
                              if (text != undefined) {
                                setViewName(text)
                              }
                            }}
                            style={{ marginLeft: "5px", width:'9vw'}}
                        />
                    </StackItem>
                    <StackItem>
                        <DefaultButton style={{ float: "right", marginTop: "7%" }} className="button-style" onClick={async (e) => {
                            saveTrainingPlanView()
                            hideSaveViewModal()
                        }}>
                            Save
                        </DefaultButton>
                    </StackItem>
                </Stack>
            </Modal>
        )
    }

    const showDialog = () => {
        return (<Dialog
            hidden={hideDialog}
            onDismiss={toggleHideDialog}
            dialogContentProps={dialogContentProps}
            modalProps={modalProps}
        >
            <ChoiceGroup label="Please select one:" defaultSelectedKey="AcheivedFirst" options={options} onChange={(e, option) => {
                switch (option?.key) {
                    case "AcheivedFirst":
                        setSortCode(SortReorderCode.ResultFirst);
                        break;
                    case "AcheivedLast":
                        setSortCode(SortReorderCode.ResultLast);
                        break;
                    case "ByUnitID":
                        setSortCode(SortReorderCode.ByUnits);
                        break;
                    default:
                        break;
                }
            }} />
            <DialogFooter>
                <PrimaryButton onClick={() => {
                    toggleHideDialog();
                    sortTPUS();
                }} text="Confirm" />
                <DefaultButton onClick={() => { toggleHideDialog(); }} text="Back" />
            </DialogFooter>
        </Dialog>);
    }

    const saveTrainingPlanView = async () => {
        let tpViewModal = new TrainingPlanViewModel(globalState.tokenModel as TokenModel);

        let response = await tpViewModal.createTrainingPlanView({...reorderState.trainingPlanView, tims_name:viewName, tims_user:globalState.tokenModel?.userId})
        if(response.error == undefined){
            const newView = {
                key: response.tims_trainingplanviewid as string,
                text: viewName as string,
              } as IDropdownOption;
        
            trainingPlanViewContext?.updateTrainingPlanViews([...trainingPlanViewContext.trainingPlanViews, newView])

            Emitter.emit(Events.SetMessageBar, { errors: [], isSuccess: true, requestType: RequestType.CREATE_VIEW, showMessageBar: true } as IMessagebarObject);
        }
        else {
            Emitter.emit(Events.SetMessageBar, { errors: [response.error?.message], isSuccess: false, requestType: RequestType.CREATE_VIEW, showMessageBar: true } as IMessagebarObject);
        }
    }

    
    const updateTrainingPlanView = async () => {
        let tpViewModal = new TrainingPlanViewModel(globalState.tokenModel as TokenModel);
        let response = await tpViewModal.updateTrainingPlanView({...reorderState.trainingPlanView, tims_user:globalState.tokenModel?.userId}, reorderState.trainingPlanView.tims_trainingplanviewid as string)
        
        if(response.ok){
            let globalContext = { ...globalState, isColumnsDirty:false } as GlobalStateModel
            globalState.setGlobalState(globalContext)

            Emitter.emit(Events.SetMessageBar, { errors: [], isSuccess: true, requestType: RequestType.UPDATE_VIEW, showMessageBar: true } as IMessagebarObject);
        } else {
            let error = await response.json() as Serializable;
            Emitter.emit(Events.SetMessageBar, { errors: [error.error?.message], isSuccess: false, requestType: RequestType.UPDATE_VIEW, showMessageBar: true } as IMessagebarObject);
        }
    }

    const deleteTrainingPlanView = async () => {
        let tpViewModal = new TrainingPlanViewModel(globalState.tokenModel as TokenModel);
        let response = await tpViewModal.deleteTrainingPlanView(reorderState.trainingPlanView.tims_trainingplanviewid as string)

        if(response.ok){   
            let tpViewsCopy = [...trainingPlanViewContext?.trainingPlanViews as IDropdownOption[]] 
            let index = tpViewsCopy.findIndex(item => item.key == reorderState.trainingPlanView.tims_trainingplanviewid)        

            tpViewsCopy.splice(index, 1)
            trainingPlanViewContext?.updateTrainingPlanViews(tpViewsCopy)

            Emitter.emit(Events.SetMessageBar, { errors: [], isSuccess: true, requestType: RequestType.DELETE_VIEW, showMessageBar: true } as IMessagebarObject);
        } else {
            let error = await response.json() as Serializable;
            Emitter.emit(Events.SetMessageBar, { errors: [error.error?.message], isSuccess: false, requestType: RequestType.DELETE_VIEW, showMessageBar: true } as IMessagebarObject);
        }

    }



    const reorderControlReducer = (state: ReorderContState, action: any): ReorderContState => {

        switch (action.type) {
            case "SAVE_COLUMNS":
                return { reorderHelp: state.reorderHelp, showLoader: state.showLoader, trainingPlanView:action.obj.trainingPlanView } as ReorderContState;

            case "OPEN_CREATE_VIEW_MODAL":
                return { reorderHelp: state.reorderHelp, showLoader: false, trainingPlanView:state.trainingPlanView } as ReorderContState;

            case "CREATE_RESULT":
                {
                    let row = state.reorderHelp.rows.find(e => e.unitStandard?.tims_unitstandardid == action.obj.unitStandardId)
                    if(row){
                        row._resultHelper = new Result(action.obj.createdResult);
                        row.resultForm = new ResultForm(row._resultHelper, row);
                    }
                    return { reorderHelp: state.reorderHelp, showLoader: false, trainingPlanView:state.trainingPlanView } as ReorderContState;
                }
            case "CREATE_COMMENT":
                {
                    let row = state.reorderHelp.rows.find(e => e.unitStandard?.tims_unitstandardid == action.obj.unitStandardId)
                    if(row){
                        row.comment = action.obj.commentObj;
                    }
                    return { reorderHelp: state.reorderHelp, showLoader: false, trainingPlanView:state.trainingPlanView } as ReorderContState;
                }
            case "REORDER": {
                let clone = cloneDeep(state.reorderHelp);
                clone.trainingPlanUnitStandards = arrayMove(clone.trainingPlanUnitStandards, action.obj.oldIndex, action.obj.newIndex);
                clone.setSequenceNumberForTPUS(true);

                let globalContext = {
                    ...globalState,
                    IsReorderDirty: clone.trainingPlanUnitStandards.find(item => item.isDirty) != undefined
                } as GlobalStateModel;
                globalState.setGlobalState(globalContext);

                return { reorderHelp: clone, showLoader: false, trainingPlanView:state.trainingPlanView } as ReorderContState;
            }
            case "ADD_ASSESSOR_VERIFIER": {
                let clone = cloneDeep(state.reorderHelp);
                let row = clone.rows[action.obj.rowIndex];
                row._tpuModel = action.obj.updatedTPU;
                return { reorderHelp: clone, showLoader: false, trainingPlanView:state.trainingPlanView } as ReorderContState;
            }
            case "POST_SAVE":
                {
                    let clone = cloneDeep(state.reorderHelp);
                    let updateResponses = action.obj.updateResponses as Response[];
                    if (updateResponses.every(item => item.ok)) {
                        for (let index = 0; index < clone.trainingPlanUnitStandards.length; index++) {
                            const element = clone.trainingPlanUnitStandards[index];
                            if (element.isDirty)
                                element.sequenceNumber = element.newSequenceNumber;
                        }
                    }
                    let globalContext = {
                        ...globalState,
                        IsReorderDirty: !updateResponses.every(item => item.ok),
                        IsFullScreenLoaderVisible: false
                    } as GlobalStateModel;
                    globalState.setGlobalState(globalContext);
                    return { reorderHelp: clone, showLoader: false, trainingPlanView:state.trainingPlanView }
                }
                break;
            case "SAVE_ORDER":
                saveOrder(state);
                break;
            case "INIT":
                return { reorderHelp: action.obj.reorderHelp, showLoader: false, trainingPlanView:action.obj.trainingPlanView } as ReorderContState;
            default:
                break;
        }

        return { reorderHelp: state.reorderHelp, trainingPlanView:state.trainingPlanView, showLoader: false } as ReorderContState;
    }

    const [reorderState, dispatch] = React.useReducer(reorderControlReducer, { reorderHelp: {}, showLoader: true, trainingPlanView:{}} as ReorderContState);

    
    React.useEffect(() => {
        Emitter.on(Events.SaveExistingView, updateTrainingPlanView);

        return () => {
            Emitter.off(Events.SaveExistingView, updateTrainingPlanView);
        };
    }, [reorderState.trainingPlanView ])



    return (

        <Stack>
            {reorderState.showLoader ?
                <Stack style={{ display: "block", marginLeft: "auto", marginRight: "auto", textAlign: "center" }}>
                    <Loader
                        type="TailSpin"
                        color="#00BFFF"
                        height={100}
                        width={100}
                        timeout={30000} //3 secs
                    />
                </Stack> : <reorderControlContext.Provider value={dispatch}>
                    <GridControl
                        columns={reorderState.trainingPlanView} rows={reorderState.reorderHelp.rows} key={globalState.programmeObj?.tims_programmeid} componentHelper={reorderState.reorderHelp} template={Templates.Reorder}
                    ></GridControl>
                    {showDialog()}
                    {renderCreateViewModal()}
                    {renderSaveViewModal()}
                    {renderDeleteViewModal()}
                </reorderControlContext.Provider >}
        </Stack>
    )
}